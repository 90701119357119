export default {
    // Menu
    Tools: "Tools",
    Dashboard: "Dashboard",
    Agenda: "Agenda",
    Alles: "Alles",
    Welkom: "Welkom",
    Academie: "Academie",
    Bibliotheek: "Bibliotheek",
    Leeromgeving: "Leeromgeving",
    "OMJS Apps": "OMJS Apps",
    "Team Onderwijs Maak Je Samen": "Team Onderwijs Maak Je Samen",

    //general
    "Onderwijs Maak Je Samen": "Onderwijs Maak Je Samen",
    "N/A": "N/A",
    General: "Algemeen",
    Academy: "Academie",
    Events: "Activiteiten",
    Activiteiten: "Activiteiten",
    Statistics: "Statistieken",
    Attendees: "Deelnemers",
    "Delete submissions": "Verwijderde deelnemers",
    Registrations: "Aanmeldingen",
    "First name": "Voornaam",
    "Middle name": "Tussenvoegsel",
    "Last name": "Achternaam",
    Email: "E-mail",
    "Phone number": "Telefoonnummer",
    "Tel:": "Tel:",
    "Tel. number": "Tel. nummer",
    Event: "Activiteit",
    Session: "Bijeenkomst",
    Actions: "Acties",
    actions: "acties",
    View: "Bekijken",
    sir: "heer",
    madam: "mevrouw",
    Sir: "Heer",
    Madam: "Mevrouw",
    Save: "Opslaan",
    "User remark": "Opmerking van gebruiker",
    Comment: "Opmerking",
    Saved: "Opgeslagen",
    "Room not added": "Ruimte niet toegevoegd",
    "Not saved": "Niet opgeslagen",
    "{model} was saved successfully": "{model} is succesvol opgeslagen",
    "{model} was deleted successfully": "{model} is succesvol verwijderd",
    "Something went wrong while saving {model}":
        "Er ging iets mis bij het opslaan van {model}",
    "Succesfully updated": "Succesvol opgeslagen",
    "Could not update: {error}": "Kon niet bijwerken: {error}",
    "Registered to session {session} of event {event}":
        "Geregistreerd voor bijeenkomst {session} van activiteit {event}",
    Home: "Home",
    Startdate: "Startdatum",
    Place: "Plaats",
    Subscriptions: "Aanmeldingen",
    Through: "Door",
    Audience: "Doelgroep",
    Themes: "Thema's",
    Information: "Informatie",
    Meetings: "Bijeenkomsten",
    Places: "Locatie(s)",
    Price: "Prijs",
    "Friend Of Price": "Vriend Van Prijs",
    "Validated by": "Gevalideerd",
    Including: "Inclusief",
    "Internal ID": "ID Kenmerk",
    "Form ID": "Formulier ID",
    Planned: "Gepland",
    Edit: "Bewerken",
    edit: "bewerken",
    delete: "verwijderen",
    restore: "herstellen",
    All: "Alles",
    "{model} changed": "{model} aangepast",
    "Provisional turnover": "Voorlopige omzet",
    of: "van",
    and: "en",
    is: "is",
    are: "zijn",
    History: "Historie",
    Archive: "Archief",
    Quickview: "Quickview",
    Filters: "Filters",
    ID: "ID",
    Details: "Details",
    search: "zoek",
    attention: "let op",
    labels: "labels",
    since: "sinds",
    updated: "bijgewerkt",
    advisors: "adviseurs",
    "are you sure": "weet je het zeker",
    welcome: "welkom",
    "Welcome in myOMJS!": "Welkom in mijnOMJS!",
    "Do you participate in a training, course or education? MyOMJS takes you to the digital learning environment of the Onderwijs Maak Je Samen Academy.":
        "Neem je deel aan een training, leergang of opleiding? Via mijnOMJS kom je terecht in de digitale leeromgeving van de OMJS Academie.",
    "myOMJS is still under development. In the future you will find more information here. For questions, mail to {email}.":
        "mijnOMJS is volop in ontwikkeling. In de toekomst zul je hier steeds meer informatie terug gaan vinden. Voor vragen, mail naar {email}.",
    organization: "organisatie",
    name: "naam",
    private: "privé",
    "Your changes were saved successfully":
        "Je veranderingen zijn succesvol opgeslagen",
    your: {
        formal: "uw",
        informal: "jouw"
    },
    "your current": "je huidige",
    type: "type",
    academy: "academie",
    Day: "Dag",
    New: "Nieuw",
    "'friend of' price": "'vriend van' prijs",
    "discount on": "korting op",
    "terms and conditions": "algemene voorwaarden",
    "privacy policy": "privacyverklaring",
    Error: "Foutmelding",
    "Remove failed": "Verwijderen mislukt",
    user: "gebruiker",
    remove: "verwijder",
    "select existing {item}": "selecteer bestaand(e) {item}",
    "or create a new {item}": "of maak een nieuw(e) {item}",
    "add another {item}": "voeg nog een {item} toe",
    "remove {item}": "verwijder {item}",
    "assign different {item}": "wijs ander(e) {item} toe",
    "or edit current {item}": "of wijzig huidig(e) {item}",
    "edit {item}": "wijzig {item}",
    "invoice email address": "e-mailadres voor de factuur",
    image: "afbeelding",
    "view image": "bekijk afbeelding",
    "download image": "download afbeelding",
    "Could not retrieve file: {error}": "Kon bestand niet ophalen: {error}",
    yes: "ja",
    no: "nee",
    sort: "sorteren",
    ascending: "oplopend",
    descending: "aflopend",

    //Validations
    title: "aanhef",
    first_name: "voornaam",
    middle_name: "tussenvoegsel",
    last_name: "achternaam",
    phone_number: "telefoon nummer",
    srpo_register_number: "SRPO registratienummer",
    "The {field} may only contain letters, numbers, dashes and underscores.":
        "Het {field}-veld mag alleen letters, nummers, streepjes en lage streepjes bevatten.",
    "The {field} may only contain letters.":
        "Het veld {field} mag alleen letters bevatten.",
    "The {field} field is required.": "Het veld {field} is verplicht.",
    "The {field} must be between 10 and 13 digits.":
        "Het {field}-veld moet tussen 10 en 13 cijfers bevatten.",
    "The {field} format is invalid.":
        "De structuur van het veld {field} is niet correct.",
    "The {field} field is required when {otherField} is {otherValue}.":
        "Het veld {field} is verplicht als {otherField} {otherValue} is.",
    "The {field} field is required unless {otherField} is in {otherValue}.":
        "Het veld {field} is verplicht tenzij {otherField} {otherValue} bevat.",
    "The password must {rules}": "Het wachtwoord moet {rules}",
    "be between {min} and {max} characters long":
        "tussen {min} en {max} karakters lang zijn",
    "be at least {min} characters long": "minstens {min} karakters lang zijn",
    "contain at least {lower} lower case letter{plural}":
        "minstens {lower} kleine letter{plural} bevatten",
    "contain at least {upper} upper case letter{plural}":
        "minstens {upper} hoofdletter{plural} bevatten",
    "contain at least {digit} digit{plural}":
        "minstens {digit} cijfer{plural} bevatten",
    "contain at least {symbol} of the following symbols: {allowedSymbols}":
        "minstens {symbol} van de volgende karakters bevatten: {allowedSymbols}",
    "The {field} and {otherField} must match.":
        "De velden {field} en {otherField} moeten overeenkomen.",
    "The {field} has already been taken.": "De {field} is reeds in gebruik",
    "The {field} must be accepted.": "{field} moet geaccepteerd worden",
    "The {field} does not match the format {format}.":
        "Het {field} veld voldoet niet aan het format {format}",
    "The {field} must be a date after {otherField}.":
        "De {field} moet een datum na {otherField} zijn.",

    //Permissions
    "No access": "Geen toegang",
    "You do not have permission to view that page":
        "Je hebt geen toestemming om die pagina te bekijken",
    "Not logged in": "Niet ingelogd",
    "Please login to continue": "Graag inloggen om verder te gaan",
    Unauthorized: "Niet bevoegd",
    "You are not authorized to perform this action":
        "Je bent niet bevoegd om deze actie uit te voeren",
    "This action is unauthorized":
        "Je bent niet bevoegd om deze actie uit te voeren",

    //Address
    address: "adres",
    street: "straat",
    house_nr: "huisnr",
    addition: "toevoeging",
    zip_code: "postcode",
    city: "plaats",
    country: "land",
    "billing address": "factuuradres",
    brin: "brin",
    organization_name: "organisatienaam",

    //agGrid
    "columns to export": "exporteer kolommen",
    export: "exporteer",
    "export all": "exporteer alles",
    "show columns": "toon kolommen",

    //transfer
    transfer: "verplaats",
    "colmns to transfer": "verplaats deelnemer",
    "For selected all registrations":
        "Alle inschrijvingen met dezelfde inzending id worden mee verplaatst.",
    "Attendees successfully transferred": "Deelnemers succesvol overgezet",
    "Somethinig went wrong while transferring attendees":
        "Er ging iets mis bij het overzetten van de deelnemers",

    //statussen
    Full: "Vol",
    // "Passage": "Open",
    Cancelled: "Geannuleerd",
    Passage: "Gaat door",
    Inactive: "Inactief",
    Open: "Open",
    Deleted: "Verwijderd",
    Started: "Gestart",
    full: "vol",
    cancelled: "geannuleerd",
    passage: "gaat door",
    inactive: "inactief",
    open: "open",
    deleted: "verwijderd",
    started: "gestart",

    // event plan-tool
    // Types
    education: "opleiding",
    Education: "Opleiding",
    training: "training",
    Training: "Training",
    "study day": "studiedag",
    "Study day": "Studiedag",
    course: "leergang",
    Course: "Leergang",
    "study trip": "studiereis",
    "Study trip": "Studiereis",
    "informational meeting": "informatie-bijeenkomst",
    "inspirational session": "inspiratie-bijeenkomst",
    Internal: "Intern",
    Free: "Gratis",
    free: "Gratis",
    "OMJS Ledenvergadering": "OMJS Ledenvergadering",
    Masterclass: "Masterclass",
    "Incompany opleiding": "Incompany opleiding",
    // titles
    "Create event": "Maak activiteit",
    Title: "Titel",
    "Subtitle(s) / pay-off": "Subtitel(s) / pay-off",
    By: "Door",
    Room: "Ruimte",
    Content: "Inhoud",
    Yield: "Opbrengsten",
    Summary: "Samenvatting",
    "Intended for who?": "Voor wie bedoeld?",
    "Target audience": "Doelgroep",
    Sector: "Sector",
    Certification: "Certificering",
    Products: "Producten",
    Concept: "Concept",
    Description: "Beschrijving",
    "Suggested price": "Prijssuggestie",
    "Date, time & location": "Datum, tijd & locatie",
    Size: "Omvang",
    "Header photo": "Header foto",
    Video: "Video",
    Testimonials: "Testimonials",
    "Relevant articles/websites": "Relevante artikelen/websites",
    Category: "Categorie",
    Tags: "Tags",
    "Event type": "Type activiteit",
    "Event kind": "Soort activiteit",
    "Event library": "Activiteitenbibliotheek",
    Templates: "Sjablonen",
    Template: "sjabloon",
    Concepts: "Concepten",
    "Event code": "Activiteitcode",
    Unfinished: "Nog niet afgerond",
    Subevent: "Editie",
    Approval: "Goedkeuren",
    "custom block": "aangepast blok",
    "block title": "bloktitel",
    "block content": "blokinhoud",
    referral: "via",
    newsletter: "nieuwsbrief",
    "friend of code": "vriend-van code",
    total: "totaal",
    "select user(s)": "kies gebruiker(s)",
    "user(s)": "gebruiker(s)",
    "select session(s)": "kies editie(s)",
    "session(s)": "editie(s)",
    "Items appear here if they are modified or new":
        "Hier verschijnen activiteiten als ze aangepast of nieuw zijn",
    "Items appear here if they have been deactivated. They will not be visible in the academy. These activities can be activated again.":
        "Hier verschijnen gedeactiveerde activiteiten die niet getoond zullen worden in de academie. Deze activiteiten kunnen weer geactiveerd worden.",
    "see approve": "zie goedkeuren",

    // subtitles
    "Think of Group 3, fun and easy - from playing to playful learning":
        "Denk aan 'Groep 3 onder de knie - Van spelen naar spelend leren'",
    "Who executes the training/education/study day":
        "Wie voert deze training/opleiding/studiedag etc, uit?",
    "Study load hours": "Studiebelastinguren",
    "Clearify the subject of the training. Avoid being short, prefer something more extensive so potential attendees really get a clear picture of the training and want to register. What is the problem you want to taclke? The urgency? Do you have a practical example or questions that describes the content?":
        "Maak duidelijk waar de training over gaat en welke inhouden aan bod komen. Probeer te voorkomen dat je hier te beknopt bent. Liever iets uitgebreider zodat potentiele deelnemers echt een duidelijk beeld krijgen van de training en zich willen inschrijven. Wat is het 'probleem' wat je aan wil pakken? De urgentie? Heb je een praktijkvoorbeeld of denkvragen die je hier kunt stellen waarmee je de inhoud evt. ook kunt beschrijven?",
    "Please summarize, separated by commas.":
        "Graag opsommen, door komma's gescheiden.",
    "Maximum wordcount of 70": "Maximaal 70 woorden.",
    "Eg. Managers in lower school that like to enrich themselves with knowledge about specific didactics for the young child and who want to gain insight in to their leadership role.":
        "Bijv. 'Leidinggevende in de onderbouw die zich willen verrijken met kennis over de specifieke didactiek voor het jonge kind en die inzicht willen krijgen in hun leidinggevende rol.'",
    "Select your target audience(s). Are you missing a target audience? Please contact the administrator.":
        "Selecteer je doelgroep(en). Mis je een doelgroep? Neem contact op met de administratie",
    "Type the products that the attendees wil receive, press enter to confirm.":
        "Typ de producten die deelnemers  ontvangen, druk op enter om te bevestigen.",
    "Price is based on number of half-days and products. These are customizable.":
        "Deze prijs is gebaseerd op het aantal dagdelen en de producten. Deze kun je aanpassen.",
    "Separated by commas": "Door komma gescheiden.",
    "separated by commas": "door komma gescheiden.",
    "Select your category. Are you missing a category? Please contact the administrator.":
        "Selecteer je categorie(en). Mis je een categorie? Neem contact op met de administratie",
    "Which keywords should be able to find this training? Enter below, separated by commas.":
        "Op welke steekwoorden moet je deze training kunnen vinden? Typ ze hieronder. Gescheiden door komma's.",
    "Use a name to differentiate between sessions.</br>Normally, name is comprised of location, city and time period":
        "Gebruik een naam om onderscheid te maken tussen bijeenkomsten.</br>Normaal gesproken wordt de naam opgebouwd uit locatie, stad en periode",
    "Specify the code used for the internal reference.<br/>You can select an existing one from the list, or start typing to create a new one.<br/>Only letters and numbers are allowed.":
        "Geef hier de code op voor de interne referentie.<br/>U kunt een bestaande uit de lijst selecteren, of in het veld typen om een nieuwe aan te maken.<br/>Alleen letters en cijfers zijn toegestaan.",
    "Preferred image resolution is 2880x1000":
        "2880x1000 heeft de voorkeur voor de afbeeldingsresolutie",

    // buttons
    "Event plan-tool": "Event plan-tool",
    "Create your event": "Maak je activiteit",
    "Edit & turn off": "Bewerk & zet uit",
    "Turn off": "Zet uit",
    "Turn on": "Zet aan",
    Expired: "Verlopen",
    Confirm: "Bevestig",
    "Wait for approval": "Wacht op goedkeuring",
    Approve: "Keur goed",
    "add new subevent": "voeg nieuwe editie toe",
    Continue: "Ga verder",
    "Show example": "Bekijk voorbeeld",
    "Delete event": "Wis activiteit",
    "Step 1": "Stap 1",
    "Step 2": "Stap 2",
    "Step 3": "Stap 3",
    "Save (as concept)": "Opslaan (als concept)",
    Incompany: "Incompany",
    Other: "Overig",
    other: "overig",
    Next: "Volgende",
    Back: "Terug",
    Submit: "Verzenden",
    "Show archive": "Bekijk archief",
    "change status": "Wijzig status",
    // loose words
    "half-days in": "dagdelen in",
    "day(s)": "dag(en)",
    minimum: "minimaal",
    maximum: "maximaal",
    "(incl. VAT)": "(incl. BTW)",
    Website: "Website",
    "Is live": "Staat live",
    // placeholders
    "Start typing": "Start met typen",
    "Select a date": "Selecteer een datum",
    "From:": "Van:",
    "Until:": "Tot:",
    Area: "Plaats",
    "Select executive": "Selecteer een uitvoerder",
    "Paste YouTube-url": "Plak hier nog een YouTube-url",
    "Session name": "bijeenkomstnaam",
    "Paste a YouTube-url here": "Plak hier een YouTube-url",
    "Drag and drop an image here, or select...":
        "Sleep hier een foto in of selecteer...",
    "Select or create an event code": "Selecteer of maak een activiteitcode",
    "Insert text here...": "Voeg hier tekst in...",
    // messages
    "If you change the place data here, you change it for every (sub)event which uses the same location.":
        "Als je de plaatsgegevens hier wijzigt, wordt dit voor elke editie toegepast die dezelfde locatie gebruikt.",
    "If you change the room data here, you change it for every (sub)event which uses the same room.":
        "Als je de ruimtegegevens hier wijzigt, wordt dit voor elke editie toegepast die dezelfde ruimte gebruikt.",
    "If you only want to change it for this (sub)event, create a new location.":
        "Als je het alleen voor deze editie wil wijzigen, maak dan een nieuwe locatie aan.",
    "If you only want to change it for this (sub)event, create a new room.":
        "Als je het alleen voor deze editie wil wijzigen, maak dan een nieuwe ruimte aan.",
    "The input is not a valid youtube link, but will be saved":
        "De invoer is geen geldige youtube link, maar zal wel worden opgeslagen",
    "An event with the same code is already approved and/or is live":
        "Een activiteit met dezelfde code is al goedgekeurd en/of staat al live",
    "Are you sure you want to remove this subevent?":
        "Weet je zeker dat je deze editie wil verwijderen?",
    "Are you sure you want to turn off this subevent?":
        "Weet je zeker dat je deze activiteit wil uitzetten?",
    "Are you sure you want to turn on this subevent?":
        "Weet je zeker dat je deze activiteit wil aanzetten?",
    "Something went wrong": "Er ging iets verkeerd",
    "Changes not saved": "Aanpassingen niet opgslagen",
    "Approve event": "Activiteit goedkeuren",
    "All editions will be published and made available on the academy website":
        "Alle edities in deze activiteit zullen worden gepubliceerd en beschikbaar worden gemaakt op de academie website",
    "Event saved as concept": "Activiteit is opgeslagen als concept",
    "could not find file '{file}' for field '{field}'":
        "kon bestand '{file}' niet vinden voor veld '{field}'",
    "unknown file": "onbekend bestand",

    //sessions
    Sessions: "Bijeenkomsten",
    Schedule: "Planning",
    Edition: "Edities",
    "Scheduled date": "Geplande datum",
    "Schedule archive": "Editie archief",
    "Editie archief": "Editie archief",
    "Internal reference": "ID Kenmerk",
    Checklist: "Checklist",
    Date: "Datum",
    date: "datum",
    Meeting: "Bijeenkomst",
    meeting: "bijeenkomst",
    Location: "Locatie",
    Time: "Tijd",
    "No data Available": "Geen informatie beschikbaar",
    TODO: "TODO",
    "Executor(s)": "Uitvoerder(s)",
    Planning: "Planning",
    "{num_attendees}/{num_synced} attendees":
        "{num_attendees}/{num_synced} deelnemers (klik voor bijwerken)",
    "to canvas": "Synchroniseer naar digitale academie",
    "update attendees": "Synchroniseer deelnemers digitale academie",
    "synchronized session to canvas":
        "bijeenkomst gesynchroniseerd naar de digitale academie",
    "synchronized attendees to canvas":
        "deelnemers gesynchroniseerd naar de digitale academie",
    "something went wrong while synchronizing: {msg}":
        "er ging iets mis tijdens het synchroniseren: {msg}",
    "Associated course could not be found":
        "Bijbehorende cursus kon niet gevonden worden",
    "start/end time": "start-/eindtijd",
    "Update edition status": "Wijzig editie status",
    "Delete current filter(s)": "Verwijder de filter(s)",

    //events
    Name: "Naam",
    "room status": "Ruimte status",
    Duration: "Looptijd",
    SBU: "SBU",
    Type: "Type",
    "Total revenues": "Totale opbrengst",
    Target: "Target",
    Status: "Status",
    "Event: {event}": "Activiteit: {event}",
    "Event details": "Activiteit details",
    "start date": "startdatum",
    revenue: "omzet",
    "you are about to delete this event":
        "u staat op het punt deze activiteit te verwijderen",
    "total revenue": "Totale opbrengst",
    "planned revenue": "Toekomstige opbrengst",

    //forms
    Cancel: "Annuleren",
    cancel: "annuleren",
    Create: "Aanmaken",
    Update: "Bijwerken",
    update: "bijwerken",
    Delete: "Verwijderen",
    "Sorry, no matching options.": "Sorry, geen overeenkomende opties.",
    " out of 70 typed, ": " van de 70 woorden gebruikt, nog ",
    " left": " over",

    //Advisors
    advisor: "adviseur",
    internal: "intern",
    external: "extern",
    "select type of advisor": "selecteer type adviseur",
    "delete {item}": "{item} verwijderen",
    "you are about to delete this {item}. Are you sure?":
        "je staat op het punt dit {item} te verwijderen. Weet je het zeker?",
    "you are about to delete this. Are you sure?":
        "je staat op het punt dit te verwijderen. Weet je het zeker?",

    //Products
    "Product(s)": "Producten",
    "Go to the product in our ": "Bekijk in de",
    webshop: "webwinkel",
    "price of products": "prijs van producten",
    "calculate manually": "bereken handmatig",
    "+ maybe € 15 for lunch/diner for each daypart (calculate manually)":
        "+ eventueel € 15 voor lunch/diner per dagdeel (bereken handmatig)",
    possibly: "eventueel",
    "for lunch/dinner per half day (calculate manually)":
        "voor lunch/diner (bereken handmatig)",

    //Tasks
    Taken: "Taken",
    Tasks: "Taken",
    Task: "Taak",
    Todo: "Todo",
    Outstanding: "Openstaand",
    "the task": "de taak",
    "New task": "Nieuwe taak",
    "Flagged {task} as {flag}": "{task} aangemerkt als {flag}",
    "Removed {flag} from {task}": "{task} is niet meer {flag}",
    "Moved {task} to archive": "{task} verplaatst naar archief",
    "Removed {task} from archive": "{task} uit archief gehaald",
    "Checklist ({completed}/{total})": "Checklist ({completed}/{total})",
    future: {
        planned_for: "Gepland voor ",
        future: "Toekomstig"
    },
    "Meeting tasks": "Bijeenkomsttaken",
    "Task for all meetings changed": "Taak voor alle bijeenkomsten aangepast",
    "All tasks": "Alle taken",
    "Marketing planning": "Marketing planning",
    "days left": "dagen resterend",
    "next week or later": "volgende week of later",
    "deadline date": "einddatum",
    deadline_date: "einddatum",
    "schedule date": "geplande datum",
    schedule_date: "geplande datum",
    "Add task": "Taak toevoegen",
    "task changed": "Taak veranderd",
    "task deleted": "Taak verwijderd",
    "there are currently no tasks": "Er zijn op dit moment geen taken",
    "you are about to delete this task":
        "Je staat op het punt deze taak te verwijderen",
    "Event name": "Activiteitnaam",
    "event name": "activiteitnaam",

    //Task flags
    completed: "Afgerond",
    favourite: "Favoriet",
    important: "Belangrijk",
    trashed: "Archief",
    flags: {
        todo: "openstaand",
        lower: {
            completed: "afgerond",
            favourite: "favoriet",
            important: "belangrijk",
            trashed: "archief"
        }
    },
    task: {
        type: {
            active: "Deze week",
            planned: "Volgende week of later"
        }
    },
    "There are currently no tasks": "Er zijn momenteel geen taken",

    //Task tags
    administration: "administratie",
    marketing: "marketing",
    organisation: "organisatie",
    email: "e-mail",
    "direct mail": "direct mail",
    custom: "algemeen",
    event: "activiteit",

    //Attendees
    attendee: "deelnemer",
    attendees: "deelnemers",
    "Edit remarks: {attendeeName}": "Opmerkingen bijwerken: {attendeeName}",
    "User remark(s)": "Opmerking(en) gebruiker",
    "Internal remark(s)": "Interne opmerking(en)",
    Notes: "Notitie(s)",
    "{model} was saved successfully for {attendeeName}":
        "{model} is succesvol opgeslagen voor {attendeeName}",
    "Something went wrong while saving {model} for {attendeeName}":
        "Er ging iets mis bij het opslaan van {model} voor {attendeeName}",
    "Submission ID": "Inzending ID",
    "add attendee": "voeg deelnemer toe",
    "Add Event": "voeg agendapunt toe",
    "add agenda": "voeg agendapunt toe",
    "transfer selected attendee": "verplaats geselecteerde deelnemers",
    "Select a row": "selecteer een rij",
    "There is no session to transfer to":
        "Er is geen andere sessie waar je naar kan verplaatsen",
    "mail all attendee": "mail alle deelnemers",
    "select edition to enroll the attendee in":
        "selecteer editie om deelnemer aan toe te voegen",
    "select existing user": "selecteer bestaande gebruiker",
    "or create a new user": "of maak een nieuwe gebruiker aan",
    "Attendee added": "Deelnemer toegevoegd",
    "Attendee not added": "Deelnemer niet toegevoegd",
    "Agree to increase the number of participants":
        "Ga akkoord met een verhoging van het aantal deelnemers",
    "attendee already enrolled": "de gebruiker neemt al deel",
    "Registration updated": "Registratie bijgewerkt",
    "The attendee has successfully been added to the edition":
        "De gebruiker is succesvol toegevoegd als deelnemer aan de editie",
    "The registration has successfully been updated":
        "De registratie is succesvol bijgewerkt",
    "Successfully removed": "Succesvol verwijderd",
    "Are you sure you want to remove this attendee?":
        "Weet je zeker dat je de deelnemer wil verwijderen?",
    "send registration email to user":
        "stuur registratie e-mail naar gebruiker",
    "If you change the address for this attendee, the other attendees from the same registration will retain the original address.":
        "Als je het adres wijzigt voor deze deelnemer, zullen de andere deelnemers van dezelfde inschrijving nog hun originele adres behouden.",
    "attendee {attendee} already enrolled":
        "deelnemer {attendee} is al ingeschreven",
    gender: "geslacht",
    given_names: "voornamen",
    billing_city: "plaats",
    billing_number: "nummer",
    billing_street: "straat",
    billing_zip_code: "postcode",
    billing_country: "land",
    email_confirm: "e-mail bevestigen",
    function: "functie",
    maiden_name: "meisjesnaam",
    agreement: "akkoord",
    phone_number_trip: "telefoonnummer tijdens de reis",
    private_address_street: "straat",
    private_address_number: "nummer",
    private_address_addition: "toevoging",
    private_address_zip_code: "postcode",
    private_address_city: "plaats",
    private_address_country: "land",
    home_contact_first_name: "voornaam",
    home_contact_middle_name: "tussenvoegsel",
    home_contact_last_name: "achternaam",
    home_contact_phone_number: "telefoonnummer",
    extra_contact_first_name: "voornaam",
    extra_contact_middle_name: "tussenvoegsel",
    extra_contact_last_name: "achternaam",
    extra_contact_phone_number: "telefoonnummer",

    //Notes
    note: "notitie",
    "There are currently no notes": "Er zijn op dit moment geen notities",
    "New note": "Nieuwe notitie",
    "Add note": "Voeg notitie toe",
    "note added": "notitie toegevoegd",
    "note changed": "notitie bijgewerkt",
    "note deleted": "notitie verwijderd",
    "you are about to delete this note":
        "je staat op het punt deze notitie te verwijderen",

    //Login screens
    Login: "Inloggen",
    Logout: "Uitloggen",
    "Welcome back, please login to your account.":
        "Welkom terug, meld je hier aan.",
    "E-mail address": "E-mailadres",
    Password: "Wachtwoord",
    "Remember Me": "Onthoud mij",
    "Forgot Password?": "Wachtwoord vergeten?",
    "Resend Verification Mail": "Verificatie mail opnieuw verzenden",
    "Enter your email here": "Voer hier je email in",
    "Email verification": "Email verificatie",
    "Repeat password": "Wachtwoord herhalen",
    "I accept the processing of my personal information as described in the ":
        "Ik ben akkoord met het verwerken van mijn persoonsgegevens zoals omschreven in de ",
    Register: "Registreer",
    "Create Account": "Account aanmaken",
    "Create your account in the form below":
        "Maak je account aan met behulp van onderstaand formulier.",
    "Back to login": "Terug naar login",
    "Resend mail": "Verzend mail",
    "Recover password": "E-mail sturen",
    "Recover your password": "Wachtwoord vergeten",
    "If an account exists with the given e-mailaddress, you will receive an e-mail with a reset link":
        "Als er een account is met dit e-mailadres dan ontvang je een e-mail met een link waarmee je een nieuw wachtwoord kunt instellen. Mocht je niets hebben ontvangen, controleer dan je spambox.",
    "If an account exists with the given e-mailaddress, you will receive an e-mail with a new verification link":
        "Als er een account is met dit e-mailadres dan ontvang je een e-mail met een link waarmee je het email adres kunt activeren. Mocht je niets hebben ontvangen, controleer dan je spambox.",
    "This account has already been verified": "Dit account is al geverifieerd",
    "You have tried this too many times please try again later":
        "U heeft dit te vaak geprobeerd probeer het op een later moment nog een keer ",
    "Something went wrong while sending the email please try again":
        "Er is iets fout gegaan met het versturen van de mail probeer het opnieuw",
    "Reset password": "Wijzig wachtwoord",
    "Please enter your new password.": "Voer hier je nieuwe wachtwoord in.",
    "Your password has successfully been changed":
        "Je wachtwoord is succesvol gewijzigd",
    "Unauthorized login attempt!":
        "De combinatie van het e-mailadres en het wachtwoord is niet bij ons bekend.",
    "The given data was invalid.":
        "De opgegeven gegevens zijn foutief, controleer de gegevens en probeer opnieuw",

    //canvas
    "but with notices": "maar met opmerkingen",
    "no exact match found for blueprint course with code {code}":
        "geen exacte match gevonden voor blauwdrukcursus met code {code}",
    "could not create course with blueprint code {code} because {reason}":
        "kon geen cursus aanmaken met blauwdrukcode {code} want {reason}",
    "user with id {id} could not be created":
        "gebruiker met id {id} kon niet aangemaakt worden",
    teachers: "adviseurs",
    "Out of sync with Canvas": "Niet synchroon met Canvas",
    "Advisors out of sync": "Adviseurs niet up to date (klik voor bijwerken)",
    "Advisors synced": "Adviseurs up to date",
    "Out of sync with canvas": "Niet synchroon met canvas",
    "{syncStatus} are not synchronized with Canvas":
        "{syncStatus} zijn niet gesynchroniseerd met Canvas",
    "something went wrong while contacting canvas: {msg}":
        "er ging iets mis tijdens het verbinden met canvas: {msg}",
    "the learning environment is temporarily unavailable, try again later":
        "de leeromgeving is tijdelijk niet beschikbaar, probeer het later nog eens",

    //Dashboard
    "Academy statistics": "Academie statistieken",
    Total: "Total",
    "Revenue & potential revenue": "Omzet & potentiële omzet",
    "Potential revenue": "Potentiële omzet",
    Revenue: "Omzet",
    thousands: " duizend",
    " registrations": " aanmeldingen",
    registrations: "aanmeldingen",
    "Total registratiions": "Totaal aantal aanmeldingen",
    "Date of registration": "Datum inschrijving",
    "Organisation/school": "Organisatie/school",
    Unknown: "-",
    Totals: "Totaal",
    "Annual review": "Jaaroverzicht",
    "From date": "Vanaf datum",

    //Profile
    Profile: "Profiel",
    "select your organization": "selecteer jouw organisatie",
    "search for your organization by name, address, BRIN-nr or establishment-nr":
        "zoek naar jouw organisatie per naam, adres, BRIN-nummer of vestigingsnummer",
    establishmentnr: "vestigingsnr",
    "cannot find your organization or not tied to an organization?":
        "kun je jouw organisatie niet vinden, of ben je niet verbonden aan een organisatie?",
    "enter address manually": "vul het adres handmatig in",
    branch: "vestiging",
    headquarters: "hoofdvestiging",
    "Profile update required": "Profielupdate vereist",
    "Please fill in your organization to continue":
        "Vul alstublieft jouw organisatie in om verder te gaan",
    "current password": "huidig wachtwoord",
    "repeat new password": "Herhaal nieuw wachtwoord",
    "New password": "Nieuwe wachtwoord",
    "old password": "oude wachtwoord",
    "new password": "nieuw wachtwoord",
    "new password confirmation": "bevestig nieuw wachtwoord",
    "password is not correct": "het wachtwoord klopt niet",
    "change password": "wachtwoord wijzigen",
    "Personal information": "Persoonlijke informatie",
    "personal information": "persoonlijke informatie",
    "Your personal information": "Jouw persoonlijke informatie",
    Salutation: "Aanhef",
    "Click to change your organization": "Vul jouw organisatie in",
    "change personal information": "Persoonlijke informatie wijzigen",
    "change organization": "organisatie wijzigen",
    "Change your organization below": "Wijzig jouw organisatie hieronder",
    "if you have changed your email, you will receive an email with a confirmation link.":
        "als je je e-mailadres hebt aangepast, ontvang je daarop een email met een bevestigingslink.",
    avatar: "Avatar",
    birthday: "geboortedatum",
    passport_names: "namen paspoort",
    place_of_birth: "geboorteplaats",
    identification_copy: "Kopie identiteitsbewijs",

    //Registration
    "Verify e-mail": "Bevestig e-mail",
    "Verifying your email, please wait":
        "Je e-mail wordt geverifiëerd, een ogenblik geduld alsjeblieft",
    "The verification link is not valid or has expired.":
        "De verificatielink is niet geldig of is verlopen.",
    "You can request a new verification link using your e-mailaddress below":
        "Je kunt hieronder een nieuwe verificatielink aanvragen met jouw e-mailadres",
    "Something went wrong while trying to send the email to the specified e-mailaddress.":
        "Er ging iets fout tijdens het verzenden van de mail naar het opgegeven e-mailadres.",
    "Please check your e-mailaddress and try again":
        "Controleer je e-mailadres en probeer opnieuw",
    "Your e-mailaddress has successfully been verified.":
        "Uw e-mailadres is succesvol geverifiëerd",
    "Your e-mailaddress has already been verified":
        "Uw e-mailadres is al geverifiëerd",
    "Continue to login": "Ga door naar inloggen",
    "Please accept the terms & conditions to register":
        "Accepteer alstublieft de voorwaarden om te registreren",
    "Please verify your e-mailaddress before logging in":
        "Verifiëer alsjeblieft eerst je e-mailadres voordat je inlogt",
    "Your registration has been received. We have sent you an e-mail to verify your e-mailadress.":
        "Controleer je mailbox voor een verificatie e-mail.",
    "account not active":
        "Er is geen actief account gevonden, neem contact op met OMJS.",
    show: "toon",
    hide: "verberg",
    Registration: "Registratie gelukt",
    "registration date": "datum van registratie",

    //Password reset
    "The provided token seems to be invalid or has expired.":
        "De opgegeven token lijkt ongeldig of is verstreken",
    "Please request a new one here": "Vraag hier een nieuwe aan",

    //Email change
    "Verifying your email change, please wait":
        "De wijziging van je e-mail wordt geverifiëerd, een ogenblik geduld alsjeblieft",
    "The change verification link is not valid or has expired.":
        "De verificatielink voor de wijziging is niet geldig of is verlopen.",
    "Please retry changing your email. If the problem persists, contact an administrator":
        "Probeer alsjeblieft nogmaals je e-mailadres te wijzigen. Als het probleem aanhoudt, neem dan contact op met een administrator",
    "Your {field} change has successfully been verified.":
        "Je {field} wijziging is succesvol gevalideerd.",
    "Your {field} change has already been verified":
        "De wijziging van {field} is al gevalideerd",

    //Klijflab
    klijflab: "klijflab",
    "Registratie ID": "Registrate ID",
    "Tel. nummer": "Te. nummer",
    sector: "sector",
    "KLIJFlab registrations": "KLIJFlab ticket verkopen",
    "KLIJFlab ticket verkopen": "KLIJFlab ticket verkopen",
    ticket_open: "enkel ticket",
    ticket_bundle: "voordeelticket",

    //Meta
    "SEO settings": "SEO-instellingen",
    "url slug": "url slug",
    "seo description": "korte beschrijving",
    "slug already in use by {event}": "slug wordt al gebruikt voor {event}",
    ticket_friend: "vriend van OMJS-ticket",

    //Error messages
    "Connection timed out": "Verbinding time-out",

    //Agenda
    "Agenda appointment not added": "Agendapunt niet toegevoegd",
    "successful saved agenda item": "agendapunt opgeslagen",
    "not Saved": "Niet toegevoegd",
    end_time: "Eind tijd",
    "Full day": "Hele dag",
    Close: "Sluit",
    "Details agendapunt": "Details agendapunt",
    "Agenda Bijeenkomsten": "Agenda Bijeenkomsten",

    //AgendaFilters
    Birthday: "Verjaardag",
    External: "Extern",
    KLIJFlab: "KLIJFlab",
    "When You Enter Left": "Alsjebinnenkomtlinks",
    "When you enter on the right": "Alsjebinnenkomtrechts",
    "When you enter straight ahead": "Alsjebinnenkomtrechtdoor",
    Brainstorm: "Brainstorm",
    Studio: "Studio",
    "Select-all": "Selecteer alles",

    //plaats
    "The place": "De plaats",
    "New place": "Nieuwe plaats",
    "New room": "Nieuwe ruimte",
    "Room will be selectable when a place is selected.":
        "Ruimte wordt selecteerbaar als er een plaats geselecteerd is.",
    "the room": "De ruimte",

    //Attendance
    attendance: "aanwezigheid",
    "updating attendance": "aanwezigheid, bijwerken",
    present: "aanwezig",

    "Whole day": "Hele dag",

    "Half days": "Halve dagen",
    Nnb: "Nog niet bekend",
    gratis: "gratis",
    "Toon SRPO registratienummerveld": "Toon SRPO registratienummerveld"
};
